<template>
  <div>
    <div class="div">
      <div class="divimage">
        <img style="margin-left: 50px" src="../assets/title.png" width="500"/>
      </div>
      <div class="divlogin">
        <el-form ref="ref" :model="model" :rules="rules"
                 style="width: 200px;height: 230px;margin: 50px 50px">
          <div class="title">
            <span class="span">网店登录</span>
          </div>
          <el-form-item prop="mobile" style="margin-bottom: 15px">
            <el-input class="family" size="small" v-model.trim="model.mobile"
                      oninput="value=value.replace(/\D/g,'')" style="width: 200px"
                      placeholder="手机号" maxlength="11" prefix-icon="el-icon-user"/>
          </el-form-item>
          <el-form-item prop="password" style="margin-bottom: 15px">
            <el-input class="family" type="password" size="small" v-model.trim="model.password"
                      placeholder="登录密码" maxlength="20" prefix-icon="el-icon-lock"
                      @keyup.enter.native="login" style="width: 200px"/>
          </el-form-item>
          <el-form-item style="margin-bottom: 15px">
            <el-button type="primary" @click="login" :loading="loadingLog" size="small">登录</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="textbutton" type="text" size="small" @click="pwd">忘记密码？</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="div1">
      <el-link class="link" href="https://beian.miit.gov.cn/"
               target="_blank" :underline="false">
        冀ICP备2024052321号-2
      </el-link>
    </div>
    <el-dialog :visible.sync="visibleCodeLogin" custom-class="dialogCodeLogin"
               append-to-body :close-on-click-modal="false">
      <div style="display: flex;justify-content: center;flex-direction: column;height: 140px;">
        <span class="span1">验证登录手机号</span>
        <div style="display: flex;align-items: center;justify-content: center">
          <el-input class="family" size="small" :value=model.mobile
                    style="width: 140px" disabled/>
          <el-button class="buttondialog" :type="codeLimit?'info':'warning'"
                     :plain="codeLimit" :disabled="codeLimit"
                     @click="getCode" :loading="loadingCode"
          >{{!codeLimit?'获取验证码':'已发送 ('+codeTime+'s)'}}
          </el-button>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 26px">
          <el-input class="family" size="small" v-model.trim="code"
                    oninput="value=value.replace(/\D/g,'')" style="width: 140px"
                    placeholder="验证码" maxlength="6" @keyup.enter.native="codeLogin"/>
          <el-button class="buttondialog" type="primary" @click="codeLogin"
                     :loading="loadingCodeLogin">确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        model: {
          mobile: '',
          password: ''
        },
        rules: {
          mobile:
            [
              {
                required: true,
                message: '请输入手机号',
                trigger: 'blur'
              },
              {
                len: 11,
                message: '手机号格式错误',
                trigger: 'blur'
              }
            ],
          password:
            [
              {
                required: true,
                message: '请输入登录密码',
                trigger: 'blur'
              }
            ]
        },
        loadingLog: false,

        code: '',
        visibleCodeLogin: false,
        loadingCode: false,
        codeTime: 60,
        codeLimit: false,
        loadingCodeLogin: false
      }
    },
    methods: {
      login () {
        this.$refs.ref.validate(async valid => {
          if (!valid) return

          try {
            this.loadingLog = true
            const { data: res } = await this.$http.post('login', this.model)
            this.loadingLog = false

            if (res.code !== 0) {
              if (res.msg === 'CODELOGIN') {
                this.visibleCodeLogin = true
                return
              }

              return this.$msg.warning(res.msg)
            }

            window.localStorage.setItem('token', res.data.token)
            window.localStorage.setItem('name', res.data.name)
            await this.$router.push('/home')
          } catch (e) {
            this.loadingLog = false
            this.$msg.error(this.netError)
          }
        })
      },
      pwd () {
        return this.$msg.warning('重置密码暂未开放，请联系管理员！')
      },

      startTimer () {
        const interval = setInterval(() => {
          if (this.codeTime > 0) {
            this.codeTime--
          } else {
            this.codeLimit = false
            clearInterval(interval)
          }
        }, 1000)
      },
      async getCode () {
        try {
          this.loadingCode = true
          const { data: res } = await this.$http.get('getLoginCode', {
            params: {
              mobile: this.model.mobile
            }
          })
          this.loadingCode = false

          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.codeLimit = true
          this.codeTime = 59
          this.startTimer()
          this.$msg.success('发送成功！')
        } catch (e) {
          this.loadingCode = false
          this.$msg.error(this.netError)
        }
      },
      async codeLogin () {
        if (this.code === undefined || this.code === '') {
          return this.$msg.warning('请输入验证码！')
        }
        if (this.code.length !== 6) {
          return this.$msg.warning('验证码格式有误！')
        }

        try {
          this.loadingCodeLogin = true
          const { data: res } = await this.$http.get('codeLogin', {
            params: {
              mobile: this.model.mobile,
              code: this.code
            }
          })
          this.loadingCodeLogin = false

          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          window.localStorage.setItem('token', res.data.token)
          window.localStorage.setItem('name', res.data.name)
          await this.$router.push('/home')
        } catch (e) {
          this.loadingCodeLogin = false
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-button {
    width: 200px;
    height: 32px !important;
    font-size: 13px;
    padding: 0;
  }

  /deep/ .textbutton {
    width: 58px;
    height: 13px !important;
    font-size: 13px;
    padding: 0;
    float: right;
    margin-right: 5px;
  }

  /deep/ .el-form-item__error {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .div {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    height: 330px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .div1 {
    display: flex;
    justify-content: center;
    width: 1000px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -10%);
  }

  .divimage {
    display: flex;
    align-items: center;
    width: 700px;
    height: 330px;
  }

  .divlogin {
    width: 300px;
    height: 330px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, .1);
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 18px;
    color: #00479D;
  }

  .span1 {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 15px;
    color: #EB6100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 20px;
    margin-bottom: 30px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 35px;
    margin-bottom: 15px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .dialogCodeLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 230px;
  }

  /deep/ .buttondialog {
    width: 90px !important;
    height: 32px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }
</style>
